<template>
  <div class="news">
    <div class="news__container _container">
      <div class="news__top">
        <h1 class="news__title main-title">{{ $t('news') }}</h1>
        <div class="news__navigation">
          <div class="news__right navigation-next">
            <span
              ><img src="../../assets/icons/swiper/arrow.svg" alt=""
            /></span>
          </div>
          <div class="news__left navigation-prev">
            <span
              ><img src="../../assets/icons/swiper/arrow.svg" alt=""
            /></span>
          </div>
        </div>
      </div>
      <div class="news__body">
        <swiper
          class="news__swiper"
          :breakpoints="breakpoints"
          :speed="650"
          :slides-per-view="3"
          :space-between="20"
          :navigation="navigation"
        >
          <swiper-slide v-for="(newsItem, idx) in news" :key="idx">
            <router-link
              :to="{ name: 'News', params: { id: newsItem.id } }"
              class="news__item"
            >
              <div class="news__image _ibg">
                <img :src="cdn + newsItem.image" alt="" />
              </div>
              <div class="news__wrapper">
                <div class="news__content">
                  <div class="news__second-title">{{ newsItem.title }}</div>
                  <div class="news__text" v-html="newsItem.description"></div>
                </div>
                <div class="news__info">
                  <div class="news__date">
                    <span class="news__icon"
                      ><img src="../../assets/icons/news/calendar.svg" alt=""
                    /></span>
                    {{ formatDate(newsItem.created_at) }}
                  </div>
                  <div class="news__views">
                    <span class="news__icon"
                      ><img src="../../assets/icons/news/eye.svg" alt=""
                    /></span>
                    {{ newsItem.shows }}
                  </div>
                </div>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { ref } from "vue";
import { Navigation } from "swiper";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      navigation: {
        nextEl: ".news__left",
        prevEl: ".news__right",
      },
      breakpoints: {
        991.98: {
          slidesPerView: 3,
        },
        767.98: {
          slidesPerView: "auto",
          spaceBetween: 10,
        },
        0: {
          slidesPerView: "auto",
          spaceBetween: 10,
        },
      },
    };
  },
  computed: {
    ...mapState(["cdn", "news"]),
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-GB", {
        year: "numeric",
        day: "numeric",
        month: "numeric",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  padding: 0 0 120px 0;
  @media (max-width: $tablet + px) {
    padding: 0 0 90px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 0 0 60px 0;
  }
  &__container {
    @media (max-width: $tablet + px) {
      padding: 0 0 0 16px;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 46px 0;
    gap: 0 20px;
    @media (max-width: $tablet + px) {
      padding: 0 16px 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      align-items: flex-end;
      margin: 0 0 35px 0;
    }
  }

  &__swiper {
    @media (max-width: $tablet + px) {
      padding: 0 16px 0 0 !important;
    }
    .swiper-slide {
      height: auto;
      @media (max-width: $tablet + px) {
        width: 316.66px;
        min-width: 244px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 244px;
        min-width: 244px;
      }
    }
  }

  &__item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #c2c2c2;
    overflow: hidden;
  }

  &__navigation {
    display: flex;
    align-items: center;
    gap: 0 8px;
  }

  &__wrapper {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding: 24px 20px 27px;
    @media (max-width: $mobileSmall + px) {
      padding: 20px 6px;
    }
  }

  &__content {
    flex: 1 0 auto;
    margin: 0 0 30px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 17px 0;
    }
  }

  &__image {
    width: 100%;
    padding-bottom: 62.75%;
  }

  &__second-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 115.38%;
    color: #2e3236;
    margin: 0 0 13px 0;
    @media (max-width: $mobileSmall + px) {
      font-size: 18px;
      margin: 0 0 8px 0;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 136.69%;
    color: #bebebe;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      height: 57px;
      max-height: 57px;
    }
  }

  &__info {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date,
  &__views {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #646464;
    @media (max-width: $mobileSmall + px) {
      font-size: 12px;
    }
  }

  &__date {
    .news__icon {
      img {
        width: 16px;
        height: 18px;
        @media (max-width: $mobileSmall + px) {
          width: 11.67px;
          height: 12.83px;
        }
      }
    }
  }

  &__views {
    .news__icon {
      img {
        width: 16px;
        height: 20px;
        @media (max-width: $mobileSmall + px) {
          width: 14px;
          height: 10.5px;
        }
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
    @media (max-width: $mobileSmall + px) {
      width: 14px;
      height: 14px;
    }
  }
}
</style>